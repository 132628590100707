import { useEffect, useState } from 'react';

import { LanguagePlugin } from 'language-plugin';
import get from 'lodash/get';

import {
  attachFonts,
  MyCheckApp,
  StorageService,
  useLanguageStore,
  useLocationStore,
  useMenuStore,
} from 'mycheck-core';

import i18n from '../core/service/i18n';
import { LanguageOption } from 'plugins/LanguagePlugin/types/LanguageTypes';

const RTL_SYMBOLS = [
  'ar',
  'arc',
  'dv',
  'fa',
  'ha',
  'he',
  'khw',
  'ks',
  'ku',
  'ps',
  'ur',
  'yi',
];

type LanguageType = {
  file: '';
  value: 'en';
  languageValue: 'en';
  longLabel: 'English';
  shortLabel: 'ENG';
};

export function useLanguageLoader() {
  const [selectedLang, setSelectedLang] = useState<LanguageOption>(null);
  const LanguageStore = useLanguageStore();
  const LocationStore = useLocationStore();
  const MenuStore = useMenuStore();

  const config = MyCheckApp.instance.getGlobalConfig();
  const { availableLanguages, default: defaultConfigLanguage } = get(
    config,
    'settings.languages',
    {
      default: 'en',
      availableLanguages: [
        {
          file: '',
          value: 'en',
          languageValue: 'en',
          longLabel: 'English',
          shortLabel: 'ENG',
        },
      ],
    },
  );

  const getBrowserPreference = () => {
    let selectedPreferredLanguage: LanguageType = null;
    navigator.languages.some((language) => {
      const normalizedLanguage = language.split('-')[0];
      selectedPreferredLanguage = availableLanguages.find(
        (obj) =>
          language.toLowerCase() === obj.languageValue?.toLowerCase() ||
          normalizedLanguage.toLowerCase() === obj.languageValue?.toLowerCase(),
      );

      return !!selectedPreferredLanguage;
    });

    return selectedPreferredLanguage;
  };

  const setDirection = (selectedLanguage: LanguageOption) => {
    const currentLanguageSymbol = selectedLanguage.value.split('_')[0];
    const element = document.documentElement;
    if (RTL_SYMBOLS.includes(currentLanguageSymbol)) {
      element.dir = 'rtl';
    } else {
      element.dir = 'ltr';
    }

    element.lang = currentLanguageSymbol;
  };

  const defaultLanguage =
    StorageService.getValue(StorageService.STORAGE_KEYS.PICKER_PREFERENCE) ||
    getBrowserPreference() ||
    availableLanguages.find((el) => el.value === defaultConfigLanguage) ||
    availableLanguages[0];

  const loadTranslation = async (
    selectedLanguage: LanguageOption = defaultLanguage,
  ) => {
    i18n.options.interpolation.defaultVariables = {
      appDisplayName: config.settings.appDisplayName,
    };
    setDirection(selectedLanguage);
    const translation = await LanguagePlugin.instance.fetchTranslation(
      selectedLanguage.file,
    );
    await LanguageStore.fetchSpecificTranslations();
    i18n.addResourceBundle(
      selectedLanguage.value,
      'translation',
      translation,
      true,
      true,
    );
    await i18n.changeLanguage(selectedLanguage.value);
    setSelectedLang(selectedLanguage);
    LanguageStore.loadTranslation(selectedLanguage);
    attachFonts(config.settings.fonts, selectedLanguage.value);

    if (LocationStore.menuTranslations[selectedLanguage.value]) {
      MenuStore.fetchMenuTranslationWithUrl(
        selectedLanguage.value,
        LocationStore.menuTranslations[selectedLanguage.value],
      );
    }
  };

  const isRtl = () => document.dir === 'rtl';

  const isLanguagePickAvailable = () =>
    !!selectedLang && availableLanguages.length >= 2;

  useEffect(() => {
    setSelectedLang(defaultLanguage);
  }, []);

  return {
    defaultLanguage,
    selectedLang,
    loadTranslation,
    availableLanguages,
    isRtl,
    isLanguagePickAvailable,
  };
}
