import React from 'react';

import { FormikProps } from 'formik';

import { RegisterSteps } from '../../RegisterPage';
import { EmailScreen } from '../EmailScreen/EmailScreen';
import { PhoneNumberScreen } from '../PhoneNumberScreen/PhoneNumberScreen';
import { UserInfoScreen } from '../UserInfoScreen/UserInfoScreen';
import { VerificationScreen } from '../VerificationScreen/VerificationScreen';
import { RegisterFormikValues } from 'plugins/AuthPlugin/types/FormikValuesType';
import { MyCheckApp, useAuthStore } from 'mycheck-core';
import { observer } from 'mobx-react';
import { useRecaptchaV3 } from '@sgs/fe-common';

interface Props {
  step: number;
  formikProps: FormikProps<RegisterFormikValues>;
  handleStepBack?: () => void;
  handleResendCodeClick?: () => void;
}

export const RegisterChain: React.FC<Props> = observer(
  ({ handleStepBack, formikProps, handleResendCodeClick, step }) => {
    const AuthStore = useAuthStore();
    const isCaptchaV3EnterpriseEnabled =
      MyCheckApp.instance.getIsCaptchaV3EnterpriseEnabled();

    const { executeRecaptcha } = useRecaptchaV3({
      siteKey: process.env.REACT_APP_RECAPTCHA_V3_KEY,
      windowObjAppId: 'dine',
      useEnterprise: isCaptchaV3EnterpriseEnabled,
    });

    const handleReCaptchav3Verify = React.useCallback(async () => {
      if (!executeRecaptcha || !isCaptchaV3EnterpriseEnabled) {
        AuthStore.setCaptchaToken(null);
        return;
      }

      const captchaToken = await executeRecaptcha('login');
      AuthStore.setCaptchaToken(captchaToken);
    }, [executeRecaptcha, AuthStore, isCaptchaV3EnterpriseEnabled]);

    React.useEffect(() => {
      handleReCaptchav3Verify();
    }, [handleReCaptchav3Verify]);

    const onHandleStepBack = () => {
      switch (step) {
        case RegisterSteps.stepTwo:
          formikProps.setFieldValue('verificationCode', '');
          break;

        case RegisterSteps.stepThree:
          formikProps.setFieldValue('email', '');
          break;

        case RegisterSteps.stepFour:
          formikProps.setFieldValue('privacyCheck', false);
          formikProps.setFieldValue('name', '');
          break;

        default:
          break;
      }

      handleStepBack();
    };

    const renderStep = (stepNumber: number) => {
      switch (stepNumber) {
        case RegisterSteps.stepOne:
          return (
            <PhoneNumberScreen
              formikProps={formikProps}
              handleReCaptchav3Verify={handleReCaptchav3Verify}
            />
          );
        case RegisterSteps.stepTwo:
          return (
            <VerificationScreen
              formikProps={formikProps}
              handleStepBack={onHandleStepBack}
              handleResendCodeClick={handleResendCodeClick}
              handleReCaptchav3Verify={handleReCaptchav3Verify}
            />
          );
        case RegisterSteps.stepThree:
          return <EmailScreen formikProps={formikProps} />;

        case RegisterSteps.stepFour:
          return <UserInfoScreen formikProps={formikProps} />;
        default:
          return null;
      }
    };

    return renderStep(step);
  },
);
